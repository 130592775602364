import React from 'react';

export const Spotify = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.9998C0 5.37255 5.37269 0 11.9999 0C18.6275 0 24 5.37255 24 11.9998C24 18.6277 18.6275 24 11.9999 24C5.37269 24 0 18.6277 0 11.9998ZM5.15726 9.25048C8.84982 8.12977 15.2302 8.34099 19.0983 10.6381C19.6303 10.9548 20.3197 10.779 20.6361 10.2457C20.9522 9.71318 20.7774 9.02436 20.2439 8.70768C15.7909 6.06432 8.7445 5.81585 4.50556 7.10249C3.91217 7.28276 3.57787 7.90938 3.75756 8.50277C3.93711 9.09572 4.56416 9.43046 5.15726 9.25048ZM17.6848 14.3488C18.1246 14.6192 18.7003 14.481 18.9717 14.0407C19.2421 13.6013 19.1037 13.0253 18.6639 12.7545C14.96 10.4782 9.54193 9.83771 5.18362 11.1603C4.68968 11.3108 4.41069 11.8324 4.55971 12.327C4.71003 12.821 5.23262 13.0998 5.72728 12.9504C9.5425 11.7923 14.4601 12.3666 17.6848 14.3488ZM17.5033 17.3082C17.2876 17.6619 16.8272 17.7726 16.4748 17.5571C13.657 15.8349 10.1101 15.446 5.93308 16.4C5.53057 16.4923 5.12934 16.2401 5.03763 15.8377C4.94535 15.4352 5.19669 15.034 5.60007 14.9421C10.1712 13.8972 14.0921 14.3469 17.2551 16.2796C17.6077 16.495 17.7188 16.9557 17.5033 17.3082Z"
        fill="#ECE9E4"
      />
    </svg>
  );
};
