import React from 'react';

export const Youtube = ({ className }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.0008 7.87469C17.5516 8.02582 17.9853 8.47113 18.1325 9.03663C18.4 10.0615 18.4 12.2 18.4 12.2C18.4 12.2 18.4 14.3384 18.1325 15.3634C17.9853 15.9289 17.5516 16.3742 17.0008 16.5254C16.0027 16.8 12 16.8 12 16.8C12 16.8 7.99733 16.8 6.99915 16.5254C6.44839 16.3742 6.01464 15.9289 5.86744 15.3634C5.60002 14.3384 5.60002 12.2 5.60002 12.2C5.60002 12.2 5.60002 10.0615 5.86744 9.03663C6.01464 8.47113 6.44839 8.02582 6.99915 7.87469C7.99733 7.60002 12 7.60002 12 7.60002C12 7.60002 16.0027 7.60002 17.0008 7.87469Z"
        fill="#ECE9E4"
      />
      <path d="M10.8 14.3999V10.3999L14 12.4L10.8 14.3999Z" fill="#ECE9E4" />
    </svg>
  );
};
